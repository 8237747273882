.home-page{
    .text-zone{
        position: absolute;
        // left: 30%;
        left: 33%;
        top: 40%;
        // transform: translate(-50%);
        transform: translate(-40%);
        width: 40%;
        max-height: 90%;
    }
    h1{
        color: #fff;
        font-size: 53px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;

        &:before{
            content: '<h1>';
            font-family: 'La Belle Aurore';
            color: #ffd700;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left: 15px;
            opacity: 0.6;
        }

        &::after{
            content: '<h1/>';
            font-family: 'La Belle Aurore';
            color: #ffd700;
            font-size: 18px;
            position: relative;
            margin-top: 18px;
            margin-left: 20px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
        }
        img{
            // margin-left: 20px; 
            // width: 32px; 
            // opacity: 0;
            // height: auto;
            // animation: rotateIn 1s linear both;
            // animation-delay: 1.4s;
            top: 10px;
        position: relative;
        margin-right: -18px;
        margin-left: 8px;
        width: 52px;
        opacity: 0;
        /* height: 61px; */
        animation: rotateIn 1s linear both;
        animation-delay: 1.4s;
        }
    }
    h2 {
        color: #8d8d8d;
        margin-top: 20px;
        font-weight: 400;
        font-size: 11px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }
    .flat-button {
    color: #ffd700;
    font-size: 13px;
    font-weight: 400;
        font-family: sans-serif;
        letter-spacing: 4px;
        padding: 10px 18px;
        border: 1px solid #ffd700;
        margin-top: 25px;
        float: left;
        text-decoration: none;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;
        &:hover{
        background: #ffd700;
        color: #333;
        }
    }
}

a {
    color: #ffffff; /* Primary link color */
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    transition: color 0.3s ease, transform 0.2s ease;
}

/* Hover Effect */
a:hover {
    color: #003366; /* Darker color on hover */
    text-decoration: underline;
    transform: scale(1.05); /* Slight scale on hover */
}

/* Active Link */
a:active {
    color: #000; /* Even darker color when clicking */
}
/* Container for bottom-right links */
.bottom-right-links {
    position: fixed;
    bottom: 20px;  /* Distance from bottom */
    right: 20px;   /* Distance from right */
    text-align: right;
    z-index: 1000; /* Keep it on top of other elements */
}

/* Styling for each link */
.bottom-right-links a {
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    display: block;
    margin: 5px 0; /* Space between links */
    transition: color 0.3s ease, transform 0.2s ease;
}

/* Hover and Active Effects */
.bottom-right-links a:hover {
    color: #ffd700;
    text-decoration: underline;
    transform: scale(1.05);
}

.bottom-right-links a:active {
    color: #003366;
}

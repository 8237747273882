.text-animate{
    display: inline-block;
    opacity: 0;
    animation: bounceIn 1s 1s;
    animation-fill-mode: forwards;
    min-width: 10px;

}
    .text-animated-hover{
        min-width: 10px;
        display: inline-block;
        animation-fill-mode: both;
        
        &:hover{
            animation: rubberband 1s;
            color: #ffd700;
        }
        
    }
    @for $i from 1 through 35 {
        .text-animated-hover_#{$i} {
            animation-delay: #{$i * 0.1}s;
            display: inline-block;
            
            &:hover{
                animation: rubberband 1s;
                color: #ffd700;
                transform: scale(1.1);
            }
            
    }
}